@import "../variable";

.wrapper {
  display: flex;
  position: relative;
}

.main {
  //flex-grow: 1;
  padding: 20px;
  //margin-right: 20px;
  transition: all 0.5s ease-in;
  width: 100%;
  @media (max-width: 520px) {
    padding: 5px;
  }
}
.swipe-side {
  &__wrap {
    background-color: black !important;
    color: white !important;
  }
  &__header {
    padding: 15px;
    display: flex;
    justify-content: flex-end;
  }
}
.close-side-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.side {
  //width: 390px;
  padding: 20px;
  //margin-left: 20px;
  transition: all 0.5s ease-in;
  min-width: 460px;
  //position: fixed;
  right: 40px;
  //overflow-y: scroll;
  height: 500px;
  width: 460px;
  //position: static;
  .sticky {
    top: 45px;
  }
}
.page-templates > .content-container > .content > .wrapper > {
  & .main, .side {
    //padding: 0px;
  }
}

.content-block {
  border-radius: 10px;
  padding: 12px 12px 9px 12px;
  background: $content-block-color;
  position: relative;
  & > .content-block {
    &:not(:last-child) {
      margin-bottom: 9px;
    }
    & > .content-block {
      background-color: transparent;
      padding: 0px;
    }
  }
}
.error-block {
  color: red;
  margin-bottom: 10px;
  font-weight: bold;
}
.in-development {
  background: rgba(0,0,0,0.8);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px
}
