.tab-button {
  display: inline-block;
  position: relative;
  padding: 0;
  margin-right: .1em;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  zoom: 1;
  overflow: visible;

  font-weight: 400;
  font-family: Verdana,Arial,sans-serif;
  font-size: 12px;
  outline: none;
}

.tab-button--left {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.tab-button--right {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.tab-button--without-bottom {
  border-radius: 6px 6px 0 0;
  border-bottom: 0!important;
  margin: 0 .2em 1px 0;
}

.tab-button--inactive {
  border: 1px solid #444;
  background: #222 url("../../../../assets/images/ui-bg_highlight-soft_35_222222_1x100.png") 50% 50% repeat-x;
  color: #eee;
}

.tab-button--active {
  border: 1px solid #26b3f7;
  background: #0972a5 url("../../../../assets/images/ui-bg_highlight-hard_20_0972a5_1x100.png") 50% 50% repeat-x;
  color: #fff;
}

.tab-button:not(.tab-button--active):hover {
  border: 1px solid #0b93d5;
  background: #003147;
  font-weight: 400;
  color: #fff;
}

.tab-button__text {
  display: block;
  line-height: 1.4;
  padding: .4em 1em;
}
