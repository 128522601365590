.tab-panel-item {
  font-family: 'Roboto', sans-serif;
  width: 24%;
  color: #aaaaaa;
  font-weight: 600;
  font-size: 0.9em;
  cursor: pointer;
  text-align: center;
  text-transform: lowercase;
}

.tab-panel-item__active{
  color: #ffffff;
  font-size: 1.1em;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
  padding-bottom: 10px;
}
