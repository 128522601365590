@import './../../../breakpoints.scss';

.progress-headers { //progress-table-one
  border-collapse: separate;
  border-spacing: 2px 9px;
  font-size: 0.9em;
  font-weight: 600;
  margin: 0 0 5px 0;
  @include _480 {
    font-size: 0.8em;
  }

  thead {
    tr {
      td {
        div {
          display: block;
          height: 2em;
          padding-top: 8px;
          font-size: 1.1em;
          color: rgba(255, 255, 255, 1.0);
        }
      }
    }
  }

  tbody {
    tr {
      color: rgba(255, 255, 255, 0.65);
      height: 1.5em;
      td {
        div {
          height: 1.5em;
          display: block;
          padding-left: 10px;
        }
      }
    }
  }
}

.progress-headers--widget {
  width: 25%;
}

.progress-headers--fullPage {
  //width: 7.14286%;
}


