.add-exercise-block {
    border-radius: 10px;
    padding: 12px 12px 9px;
    background: rgba(0,0,0,.3);

    &__input-wrapper {
      position: relative;
      width: 100%;
      margin-bottom: 5px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }

    &__input {
      flex: 4;
      padding: 8px 10px 8px 25px;
      color: hsla(0,0%,100%,.9);
      background: hsla(0,0%,100%,.05);
      border-radius: 8px;
      border: 0;
      outline: none;
      font-size: 14px;
    }

    &__search-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 7px;
      color: #818181;
    }

    &__change-all {
      margin-left: .5em;
      flex: 1;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }

    &__list {
      border-top: 1px solid hsla(0,0%,100%,.2);
    }

    &__btn {
      font-size: 14px;
      color: #c6c2c1;
      border: none;
      background: none;
      outline: none;
      cursor: pointer;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 10px;
    }

    &__fav-header {
      margin: 5px 5px 5px 10px;
      // font-size: 1.3em;
      // font-weight: bold;
    }

    &__icon-btn {
      padding: 0;
      margin: 0;
      font-size: 16px;
      background: none;
      border: none;
      outline: none;
      cursor: pointer;

      &--heart {
        color: #ff434b;
      }

      &--edit {
        color: #55b7f6;
      }
    }

    .exercise-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 40px;
      padding: 0 10px;
      cursor: pointer;
      transition: all .3s ease-in;
      color: #fff;

      &__buttons {
        display: flex;
        flex-direction: row;
        & > *:not(:last-child) {
          margin-right: 10px;
        }
      }

      &:hover {
        background: rgba(255,255,255,0.1);
      }

      &--pressed {
        background: rgba(255,255,255,0.2);
      }
    }
  }
