@import "../../../../../breakpoints";

.trainer-clients__body {
  padding: 25px 20px 15px;
  font-size: 1em;
  font-weight: 600;
  min-height: 120px;

  @include _375 {
    padding: 25px 0 15px;
  }
}

.trainer-clients__subtitle {
  font-size: 1.1em;
  margin-bottom: 10px;
  border-bottom: 1px solid hsla(0,0%,100%,.2);
  padding-bottom: 10px;
  color: rgba(255, 255, 255, 1.0);
}

.trainer-clients__spinner {
  padding: 80px 0;
}
