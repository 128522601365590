@import "mixin";

* {
  box-sizing: border-box;
}

body {
  height: 100%;
  color: #fff;
  background-color: #000;
  margin: 0;
}

.bg {
  background: black url("../images/background-inner.jpg") no-repeat center center local;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  top: 0;
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: -1;
}

[type="search"] {
  -webkit-appearance: searchfield;
}
/* Pre Render
========================================================================== */
.sk-three-bounce {
  margin: 40px auto;
  width: 80px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -40px;
}

.sk-three-bounce .sk-child {
  width: 25px;
  height: 25px;
  background-color: #3293cc;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
  animation: sk-three-bounce 1.4s ease-in-out 0s infinite both;
}

.sk-three-bounce .sk-bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.sk-three-bounce .sk-bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-three-bounce {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-three-bounce {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/*
========================================================================== */
.layout-main {
  padding: 0;
  // @media (min-width: $small) {
  //   padding: 0 10px;
  //   width: inherit;
  // }
  // @media (min-width: $normal) {
  //   padding: 0 15px;
  // }
}

.app-btn {
  &__wrapper {
    padding: 5px 10px;
  }

  &__text {
    line-height: 1.4;
    padding: 0 5px;
  }
}

.tab {
  display: inline-block;
  position: relative;
  padding: 0;
  margin-right: 0.1em;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  zoom: 1;
  overflow: visible;
  border: 1px solid #444444;
  background: #222222 url("../images/ui-bg_highlight-soft_35_222222_1x100.png") 50% 50% repeat-x;
  font-weight: normal;
  color: #eee;
  font-family: Verdana, Arial, sans-serif;
  font-size: 12px;
  outline: none;

  &:not(&--disabled):not(&--active):hover {
    @include tab-hover();
  }

  &__text {
    display: block;
    line-height: 1.4;
    padding: 0.4em 1em;
  }

  &__link {
    color: #fff !important;
    border: none !important;
    padding: 0.4em 2.1em 0.4em 1em;
    display: block;
    line-height: 1.4;
  }

  &__icon {
    width: 16px;
    height: 16px;
    background-image: url("../images/ui-icons_cccccc_256x240.png");
    background-position: -48px -192px;
    position: absolute;
    top: 50%;
    margin-top: -8px;
    right: 0.5em;
  }

  &--active {
    border: 1px solid #26b3f7;
    background: #0972a5 url("../images/ui-bg_highlight-hard_20_0972a5_1x100.png") 50% 50% repeat-x;
    font-weight: normal;
    color: #ffffff;
  }

  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &--corner-all {
    border-radius: 6px;
  }

  &--corner-right {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &--corner-left {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &--corner-top {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
}

table.paging tbody {
  td img {
    vertical-align: middle;
  }

  tr:nth-child(odd) {
    background: transparent url('../images/content-group-back.png');
  }

  tr:last-of-type {
    td:first-of-type {
      border-bottom-left-radius: 10px;
    }

    td:last-of-type {
      border-bottom-right-radius: 10px;
    }
  }
}


