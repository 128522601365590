.trainers-page {
  .table__rigth-cell {
    width: auto !important;
    min-width: auto !important;
    color: rgb(82, 115, 132)!important;
  }

  .list-item {
    text-align: center;
  }

  .table__link {
    text-align: left !important;
  }
}

.mobile-drawer {
  overflow: scroll;
}
