@import 'src/_breakpoints.scss';

.drums {
  max-width: 400px;
  height: 300px;
  color: #FFFFFF;
  margin: 0 auto;
  position: relative;

  @include _428 {
    height: 400px;
    font-size: 1.3rem;
  }

  .top-shadow, .bottom-shadow {
    position: absolute;
    height: 120px;
    width: 100%;
    z-index: 2;
    pointer-events: none;
  }

  .top-shadow {
    background: linear-gradient(rgba(0,0,0,.8), rgba(0,0,0,0));
  }

  .bottom-shadow {
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.8));
    bottom: 0;
  }

  .fa {
    font-size: 12px;
    color: rgb(15, 88, 127);
    cursor: pointer;
    user-select: none;
  }

  .actions {
    display: flex;
    justify-content: space-around;
    width: 100%;
    position: absolute;
    z-index: 5;
    opacity: 1;
    color: rgb(15, 88, 127);
    font-size: 12px;
    cursor: pointer;
    user-select: none;

    @include _428 {
      font-size: 18px;
      padding: 10px 0;
    }

    *:nth-child(1) {
      flex-grow: 9;
    }
    *:nth-child(2) {
      flex-grow: 8;
    }
    *:nth-child(3) {
      flex-grow: 14;
    }
  }

  .pluses {
    bottom: 0;
  }

  &-icon {
    &__multiply {
      position: absolute;
      left: 26%;
      top: 140px;

      @include _428 {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &__eql {
      position: absolute;
      left: 56%;
      top: 140px;

      @include _428 {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .empty {
    height: 135px;
    @include _428 {
      height: 175px;
    }
  }

  .inner {
    display: flex;
    position: relative;
    max-height: 100%;
  }

  .columns {
    display: flex;
    width: 100%;
  }

  .weight, .reps {
    position: relative;
    overflow: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    scroll-behavior: smooth;
  }

  .weight {
    flex-grow: 9;
  }

  .reps {
    flex-grow: 22;
    display: flex;
    justify-content: space-around;
    &__column {
      flex-grow: 4;
    }
  }

  .max {
    &__column {
      flex-grow: 7;
    }
  }

  .weight, .reps {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .drum-item {
    display: flex;
    align-items: center;
    justify-content: center;
    //width: calc(100% + 17px);
    height: 30px;
    user-select: none;
    color: rgb(194, 194, 194);

    @include _428 {
      height: 50px;
    }

    &--highlighted {
      color: rgb(94, 181, 233);
    }

    &__new-pb {
       color: rgba(26, 143, 173, 0.83);
    }
  }

  .line {
    height: 35.3333px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 10px;
    pointer-events: none;

    @include _428 {
      top: 50%;
      transform: translateY(-50%);
      height: 50.3333px;
    }

    &--highlighted {
      background-color: rgba(62, 127, 163, 0.5);
    }
  }
}
