.menu {
  height: 100%;
  width: 300px;
  padding: 70px 20px;
  text-decoration: none;
  z-index: 101;
  color: #fff;
  font-size: 20px;
  top: 0;
  overflow-y: auto;

  &-item {
    cursor: pointer;
    padding: 10px;
  }

  a:active, a:link, a:visited {
  display: block;
  color: #fff;
  text-decoration: none;
  }

  &__close-btn {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 13px;
    left: 18px;
    cursor: pointer;
  }

  &__footer {
    position: fixed;
    bottom: 50px;
    display: flex;
    justify-content: center;
    width: inherit;
    left: 0;
    font-size: 16px;
    color: #35b5f4 !important;

    &__link {
      margin: 0 5px;
      font-weight: bold;
      color: #35b5f4 !important;
    }
  }
}

.MuiPaper-root {
  background-color: rgba(0, 0, 0, .9) !important;
}

.dropdown {
  &-placeholder {
    @extend .menu-item;

    display: flex;
    justify-content: space-between;
  }

  &-menu {
    display: flex;
    flex-direction: column;
  }

  &-option {
    margin-left: 30px;
    padding: 10px;
    color: #fff;
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
      padding: 10px;
      color: #555;
      margin-left: 30px;
    }
  }
}

