@import "../../variable";
@import "../../mixin";

.page-clients {

	.button-set {
		display: flex;
		justify-content: center;

		button {
			margin: 0;
		}
	}

	.form-result {
		display: none;
	}

	.trainer {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin: 10px 0;
		&__input {
			width: 90%;
		}
		&__remove {
			height: 36px;
			width: 36px;
			border-radius: 5px;
			font-size: 20px;
			color: #fff;
			background-image: linear-gradient(#0972a5, #2d8bd8);
			border: 1px solid #2c69c9;
			padding: 5px 8px;
			outline: none;
			&:hover {
				background-image: linear-gradient(#5fb9ef, #0b93d5);
			}
		}
	}

	.btn-add-trainer {
		border-radius: 5px;
		padding: 7px;
		font-size: 14px;
		outline: none;
		border: 1px solid #444444;
		background: #222222 url("../../../images/ui-bg_highlight-soft_35_222222_1x100.png") 50% 50% repeat-x;
		font-weight: normal;
		color: #eeeeee;
		&:hover {
			@include tab-hover();
		}
		i {
			padding-right: 5px;
		}
	}

	.ui-tabs-nav {
		li {
			flex: 1 auto;

			&:not(.ui-state-active):hover {
				border: 1px solid #0b93d5;
				background: #003147 url('../../../images/ui-bg_highlight-soft_33_003147_1x100.png') 50% 50% repeat-x;
				font-weight: normal;
				color: #ffffff;
			}

			a {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				text-align: center;
			}
		}
	}

	.form-buttons {
		margin: 16px 0;
	}

	.field-support-left {
		position: absolute;
		padding-top: 24px;
		width: 155px;
		text-align: right;
	}

	.field-full {
		width: 633px;
	}

	.field-threequarter {
		width: 452px;
	}

	.field-half {
		width: 308px;
	}

	.field-third {
		width: 200px;
	}

	.field-quarter {
		width: 146px;
	}

	.field-fifth {
		width: 110px;
	}

	.field-sixth {
		width: 92px;
	}

	.field-eighth {
		width: 65px;
	}

	.field-ninth {
		width: 55px;
	}
}

.client-info-select-wrapper{
  padding-right: 5px;
  margin: 2.5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  &__label-wrapper{
    flex: 0 1 20%;
    min-width: 125px;
  }
  &__select{
    flex: 1 1 auto;
    div{
      margin: 0;
    }
  }
}

.client-info-btn-wrapper {
  display: flex;
  flex-flow: row wrap;
  padding-top: 10px;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  div {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
  }
}

.client-list-arrow-wrapper{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  .body-arrow{
    margin: 0;
    height: 25px;
    width: 25px;
  }
}

.client-list-search-wrapper{
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px;
  .client-list-search{
    margin: 0 5px 2px;
    background: rgba(255, 255, 255, 0.1);
    float: right;
    width: 50%;
  }
}

.client-edit-button-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}

.client-progress-arrow-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 40px;
  .body-arrow {
    width: unset;
    min-width: 40px;
  }
}

.client-progress-table {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}
