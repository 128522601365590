@import '../../../breakpoints';

.list-item {
  color: rgba(255, 255, 255, 0.65);
  font-size: 1.0em;
  line-height: 1.6em;
  min-width: 200px;
  width: 100%;
  height: 100%;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, .1);
  }
}

.table__delete-cell {
  width: 4%;
  margin: auto 0;
  @media screen and (max-width: 480px) {
    //width: 25%;
    margin-right: 6px;
  }
}

.table__name-cell {
  padding-right: 5px;
  width: auto;
}

.table__name-cell-container {
  display: flex;
  align-items: center;
}

.table__edit-btn {
  display: flex;
  align-items: center;
}


.table__link {
  color: rgba(255, 255, 255, 0.6);
  border-bottom: none;
}

.table__rigth-cell {
  width: 20%; /*Частный случай. Вынести*/
  min-width: 100px;
}

.table__common-cell {
  display: flex;
  align-items: center;

  &--center {
    justify-content: center;
  }

  @include _1200 {
    justify-content: left;
  }

  @include _428 {
    justify-content: center;
  }
}
