@import url('https://fonts.googleapis.com/css?family=Roboto:100');
$black: #000;
$white: #fff;
$primary: #24a3f4;
$input-label: #a7a9a9;
//SCREEN BREAKPOINTS
$training-mobile-large: 425px;
$training-mobile-medium: 375px;
$training-mobile-small: 320px;

$body-mobile-small: $training-mobile-small;
$body-mobile-medium: $training-mobile-medium;
$body-mobile-large: $training-mobile-large;
//CONTAINER
$content-block-color: rgba(0, 0, 0, 0.3);
//BUTTONS
$btn-border-radius: 15px;
$btn-margin: 5px;
$btn-padding: 3px 10px; //было 5px
//-primary
//--main
$btn-primary-background-color: #1773a1;
$btn-primary-border-color: #5eb5e9;
$btn-primary-font-color: $white;
//--hover
$btn-primary-hover-background-color: #166590;
$btn-primary-hover-border-color: #4b90b9;
//--active
$btn-primary-active-background-color: #13597f;
$btn-primary-active-border-color: #3d7697;
//-disabled
$btn-disabled-background-color: #808080;
$btn-disabled-border-color: $white;
$btn-disabled-font-color: $white;
//-warning
//--main
$btn-warning-background-color: #f03802;
$btn-warning-border-color: #f03802;
$btn-warning-font-color: $white;
//--hover
$btn-warning-hover-background-color: #c81000;;
$btn-warning-hover-border-color: #f03802;
//--active
$btn-warning-active-background-color: #8b0b00;
$btn-warning-active-border-color: #f03802;
//--disabled
// screen sizes
$tiny: 240px;
$small: 320px;
$normal: 480px;
$medium: 768px;
$big: 992px;
$large: 1200px;

$main-font-family: 'Roboto', sans-serif
