@import '../../../../../../../breakpoints';

.trainer-client {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0 10px;

  &:hover > span {
    background-color: rgba(255, 255, 255, .1);
  }

  span {
    padding: 0 10px;
  }

  &__name {
    margin-right: 2px;
    flex-grow: 1;
    color: rgb(82, 115, 132);
    cursor: pointer;
  }

  &__status {
    flex-grow: 0;
    width: 20%;
    color: hsla(0,0%,100%,.65);
    @include _480 {
      width: auto;
    }
  }
}