@import "variable";
$dropdown-width: 200px;

header {
  height: 50px;
  width: 100%;
  margin: 0;
  padding-top: 5px;
  background: rgba(32, 32, 32, 0.6);
  text-align: center;
  font-size: 2.0em;
  position: fixed;
  right: 0px;
  top: 0px;
  z-index: 3;
  img {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 10px;
    left: 16px;
    cursor: pointer;
  }
}

.header_logo {
  height: 30px;
  width: auto;
  position: absolute;
  top: 10px;
  left: 0px;
  right: 0px;
  margin-left: auto;
  margin-right: auto;
  cursor: default;
}

// .layout-header {
//   margin: 0 -15px;
// }

// div.tools-container {
//   margin: 0;
//   padding: 0 5%;
//   height: 40px;
//   z-index: 200;
//   background: rgba(0, 0, 0, 0.6);
//   font-family: 'PTSansNarrowRegular', 'Lucida Grande', Helvetica, sans-serif;
// }

// .header__image {
//   display: none;
//   @media (min-width: $normal) {
//   		display: block;
//   }
// }

// div.tools {
//   display: flex;
//   justify-content: space-around;
//   position: relative;
//   margin: 0 auto;
//   padding: 0;
//   height: 39px;
// }

// div.tools img.logo {
//   position: relative;
//   margin-top: 2px;
//   height: 20px;
//   transform: translateY(50%);
// }

// ul.tools-navigation {
//   margin: 0;
//   padding: 0;
//   font-size: 18px;
//   height: 39px;
//   list-style-type: none;
//   @media (min-width: $small) {
//     font-size: 20px;
//   }
// }

// ul.tools-navigation li {
//   margin: 0;
//   padding: 0;
//   float: left;
// }

// a.tools:active,
// a.tools:link,
// a.tools:visited {
//   // margin: 0 6px;
//   padding: 8px;
//   // font-size: 20px;
//   color: #aeaeae;
//   display: block;
//   text-decoration: none;
// }

// a.tools:hover {
//   color: #fff;
// }

// ul.tools-navigation ul {
//   margin: 39px 0 0 10px;
//   padding: 5px 0 10px;
//   position: absolute;
//   display: none;
//   width: 200px;
//   background-image: url('../images/flyout-back.png');
//   background-position: bottom left;
//   background-repeat: no-repeat;
// }

// ul.tools-navigation ul li {
//   margin: 0;
//   padding: 0;
//   float: none;
//   list-style-type: none;
//   background-repeat: no-repeat;
//   background-position: bottom;
// }

// ul.tools-navigation ul a:active,
// ul.tools-navigation ul a:link,
// ul.tools-navigation ul a:visited {
//   margin: 0;
//   padding: 8px 10px;
//   display: block;
//   font-size: 14px;
//   color: #fff;
//   text-decoration: none;
// }

// .Dropdown-root {
//   position: relative;
// }

// .Dropdown-control {
//   padding: 7px;
//   position: relative;
//   overflow: hidden;
//   border-radius: 2px;
//   box-sizing: border-box;
//   color: #aeaeae;
//   cursor: default;
//   outline: none;
//   transition: all 200ms ease;

//   &:hover {
//     color: #fff;
//     cursor: pointer;
//   }
// }

// .Dropdown-menu {
//   background-color: rgba(0, 0, 0, 0.5);
//   box-shadow: 0 1px 0 rgba(0,0,0,0.06);
//   margin-top: -1px;
//   max-height: 200px;
//   overflow-y: auto;
//   position: absolute;
//   top: 100%;
//   width: $dropdown-width;
//   z-index: 1000;
//   margin-left: calc(100% - #{$dropdown-width});
//   border-radius: 0 0 10px 10px;
// }

// .Dropdown-option {
//   color: #fff;
//   cursor: pointer;
//   display: block;
//   padding: 8px 10px;
//   font-size: 14px;

//   &:hover {
//     background-color: rgba(123, 118, 118, 0.5);
//   }
// }
