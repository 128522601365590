@import './../../../breakpoints.scss';

.page-content {
    margin: -15px 0 0;
    position: relative;
    /*width: 66%;
    min-height: 80vh;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;*/
}

.content-rhs a:active,
.content-rhs a:link,
.content-rhs a:visited {
  color: #7BB5EE;
  text-decoration: none;
  border-bottom: 1px dotted #444;
}

div.content-rhs {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  margin: 0 auto;
}

div.content {
  color: #fff;
  background: rgba(32, 32, 32, 0.6);
  border-radius: 25px;
  position: relative;
  transition: all 0.5s ease-in;
  padding: 20px;
  @include _480 {
    padding: 0;
  }
  @media only screen and (pointer: coarse) {
    margin-top: 5px;
    border-radius: 0;
  }
}


.clients-page__content {
  margin: -15px 0 0;
  position: relative;
  width: 66%;
  min-height: 80vh;
  display: flex;
  @include _480 {
    margin: 0 0 0;
  }
  @include _1200 {
    width: 100%;
  }
}

.clients-page__body-main {
  flex-grow: 1;
  padding-right: 20px;
  width: 100%;
  @media only screen and (pointer: coarse) {
    padding: unset;
  }
  @include _480 {
    padding-right: 0;
  }
  .widget-title {
    @include _480 {
      margin-top: 0;
    }
  }
}

.clients-page__body-side {
  flex-grow: 1;
  padding-left: 20px;
  width: 33%;
  position: fixed;
  right: 40px;
  top: 75px;
  bottom: 10px;
  @include _1200 {
    width: 1%;
    padding-left: 0;
  }
}
