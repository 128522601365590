@import "../../../../../breakpoints";


.company-search {
  border-radius: 6px;
  
  padding: 5px;
    border: 1px solid #888;
    border-radius: 5px;
    font-size: 20px;
    color: #24a3f4;
    font-size: 1em;

    height: 36px;
    width: 178px; 
    font-size: 1em !important;
    background-color: rgba(0, 0, 0, 0.75);

    @include _480 {
      margin-left: 10px;
      margin-right: 10px;
      width: 97%;
    } 
}


.company-search:-webkit-autofill {
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}