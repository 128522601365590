@import './../../../breakpoints.scss';

.program-template {
  .widget-body {
    background: rgba(0,0,0,.3);
  }

  &__page-content {
    margin: -15px 0 0;
    position: relative;
    display: flex;
    box-sizing: border-box;

    h1 {
      margin: 10px 0;
    }

    @include _480 {
      margin: 0 0 0;
      top: 15px;
      flex-direction: column;
      align-items: center;
    }
  }

  &__main {
    flex-grow: 1;
    padding-right: 20px;
    width: 66%;
    color: hsla(0, 0%, 100%, .65);

    @include _1200 {
      width: 100%;
      padding-right: 0;
    }
  }

  &__side {
    flex-grow: 1;
    padding-left: 20px;
    max-width: 460px;
    width: 100%;
    height: auto;

    @include _1200 {
      padding-left: 0;
    }
  }
}

.program-template-main {
  &__input {
    background: rgba(0,0,0,.7);
    margin-bottom: 10px;
  }

  &__days {
    border-radius: 10px;
    padding: 12px 12px 9px;
    background: rgba(0,0,0,.3);
  }

  &__add-day {
    display: flex;
    align-items: center;
    padding: 10px;
    padding-left: 20px;
    background-color: rgba(0,0,0,.3);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid hsla(0,0%,100%,.2);

    span {
      font-size: 1.4em;
      margin-right: 10px;
    }
  }

  &__add-day-btn {
    i {
      font-size: 1.4em;
    }
  }

  &__error {
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 12px 12px 9px;
    background: rgba(0,0,0,.3);
    color: red;
    font-weight: 700;
  }
}
