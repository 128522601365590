.dropzone__label {
  white-space: pre;
  color: #aaa;
  font-weight: 600;
  font-size: 1.1em;
  padding-left: 5px;
  text-transform: lowercase;
}

.img_div {
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
  padding-top: 10px;

  .icons {
    background-image: url('../../assets/images/ui-icons_ffffff_256x240.png');
    background-repeat: no-repeat;
    position: absolute;
  }

  .cross {
    cursor: pointer;
    width: 15px;
    height: 15px;
    background-position: -32px -193px;
    right: 20px;
  }

  .pic_container {
    cursor: pointer;
    width: 100%;
    height: 200px;
    margin: 2px auto 10px auto;
    border: solid 2px black;
    border-radius: 20px;
    background-image: url('../../assets/images/flyout-back.png');

    .input {
      height: 100%;
      width: 80%;
      margin: 0 auto;
    }
  }
}

#color_field input {
  width: 40%;
  min-width: 170px;
}
