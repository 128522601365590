.spinner-wrapper {
  min-height: 300px;
}

.widget-wrapper {
  display: flex;
  flex-direction: column;
}

.payment-description {
  &__wrapper {
    font-size: 1.1em;
    padding-bottom: 15px;
  }

  span {
    margin: 0 5px;
  }
}

