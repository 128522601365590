@import 'src/_breakpoints.scss';

.add-program {
  @include _428 {
    .btn {
      font-size: 1.2rem;
      padding: 3px 10px;
    }

    .widget-body {
      padding: 5px 10px !important;
    }
  }

  &__button {
    padding-bottom: 15px;
    padding-top: 10px;
    color: #fff;
    background: none;
    border: none;
    outline: none;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;

    @include _428 {
      font-size: 1.1rem;
    }
  }

  &__input-wrapper {
    position: relative;
    margin-bottom: 15px;
    margin-right: 5px;
  }

  &__input {
    width: 100%;
    padding: 8px 10px 8px 25px;
    color: hsla(0,0%,100%,.9);
    background: hsla(0,0%,100%,.05);
    border-radius: 8px;
    border: 0;
    outline: none;
    font-size: 14px;
  }

  &__search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 7px;
    color: #818181;
  }
}

.add-program-item {
  margin-bottom: 20px;

  &--underlined {
    border-bottom: 1px solid hsla(0,0%,100%,.2);
  }

  &__title {
    font-size: 24px;
    color: #fff;
    margin-bottom: 10px;
  }

  &__button {
    width: 100%;
    text-align: center;
    font-size: 16px;
    color: #fff;
    background: none;
    border: none;
    outline: none;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    padding: 5px;
  }
}

.add-program-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  color: hsla(0,0%,100%,.8);
  cursor: pointer;
  transition: all .3s;

  @include _428 {
      font-size: 1.2rem;
  }

  &:hover {
    background: hsla(0,0%,100%,.1);
  }

  &__icon-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 6px;
  }

  &__icon-btn {
    font-size: .9em;
    & + & {
      margin-left: 5px;
    }

    .fa-heart {
      color: #ff434b;
    }

    .fa-edit {
      color: #55b7f6;
    }

    .fa-bolt {
      color: #000;
    }
  }
}
