@mixin clearfix() {
  &::before,
  &::after {
    content: " ";
    display: table;
  }
  &::after {
    clear: both;
  }
}

@mixin tab-hover() {
  border: 1px solid #0b93d5;
  background: #003147 url("../images/ui-bg_highlight-soft_33_003147_1x100.png") 50% 50% repeat-x;
  font-weight: normal;
  color: #fff;
}
