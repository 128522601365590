@import "src/mixins";

.history-row {
  border-radius: 10px;
  padding: 5px 15px;
  cursor: pointer;

  &:hover {
    background: hsla(0,0%,78%,.1);
  }

  &__info  {
    display: flex;
    @include flex-gap(10px, column);
    color: hsla(0,0%,100%,.7);
    margin-bottom: 5px;
  }

  &__title {
    flex: 1;
  }

  &__item {
    flex: 0;
    white-space: nowrap;
  }

  &__addition {
    font-size: .8em;

    span {
      padding: 0 10px;
    }
  }
}
