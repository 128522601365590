@import "../../constants";
.body-comp-graph-col-wrap {
  position: relative;
  display: flex;
  align-items: flex-end;

  &-current {
    .strength-slider {
      left: -$bodyWidth + 15;
      @include _768 {
        left: -$mobileSmallBodyWidth + 10;
      }
    }
  }

  &-goal {
    .strength-slider {
      left: $bodyWidth - 15;
      @include _768 {
        left: $mobileSmallBodyWidth - 10;
      }
    }
  }
}

.body-slider {
  width: $bodyWidth;
  border-radius: $bodyBorderRadius;
  padding: 0;

  @include _768 {
    width: 80px;
  }


  @include _428 {
    width: $mobileLargeBodyWidth;
  }
  @include _375 {
    width: $mobileMediumBodyWidth;
  }
  @include _320 {
    width: $mobileSmallBodyWidth;
  }
}

.body-slider.strength-slider {
  position: absolute;
  bottom: -75px;

  @include _428 {
    bottom: -40px;
  }
}
