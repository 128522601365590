.companies-table__item {
  .cell--name,
  .cell--trainers,
  .cell--clients {
    padding: 5px 5px 5px 10px;
    color: #fff;
    font-size: 12px;
    line-height: 25px;
  }

  .cell--trainers,
  .cell--clients {
    width: 10%;
  }
}

