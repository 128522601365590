.new-diet {
  &__btn {
    flex: 1;
  }

  &__name {
    flex: 19;
  }

  .templates-list {
    background: unset;
    background: transparent;

    &__header {
      margin-left: 5%;
      padding: 10px 0;
      font-size: 1.1em;
      font-weight: 600;
      color: #fff;
    }

    &__item {
      padding: 8px 0;
      display: flex;
      align-items: center;
      font-size: .9em;
      font-weight: 600;

      &:hover {
        cursor: pointer;
        background-color: hsla(0,0%,100%,.1);
      }
    }
  }
}
