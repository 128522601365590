.food-editor {
  background: none;
}

.food-editor__formfield {
  padding: 10px 5px;
}

.food-editor__foods-select {
  border: 0;
  color: hsla(0, 0%, 100%, .6) !important;
  padding: 5px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 1em;
  outline: none;
  margin: 8px 0 0;
  float: right;
  width: 55%;

  & option {
    cursor: pointer;
    background: #000 !important;
  }
}

.food-editor__foods-inline {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin: 5px;
  border-bottom: 1px solid hsla(0, 0%, 100%, .2);
}

.food-editor__btn {
  float: right;
  display: block;
}

.food-editor__modal {
  outline: 2px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);


}

.food-editor__modal-inner {
  border: 1px solid rgb(204, 204, 204);
  outline: none;
  padding: 20px;
  inset: 50% auto auto 50%;
  overflow: auto;
  border-radius: 4px;
  color: black;
  max-width: 400px;
  width: 100%;

  .field-food-label {
    white-space: pre;
    color: #aaa;
    font-weight: 600;
    font-size: 1.1em;
    padding-left: 5px;
    text-transform: lowercase;
  }

  .foods-input {

    padding: 5px;
    margin: 8px 0 0;
    border: 0 solid #888;
    border-radius: 10px;
    color: hsla(0,0%,100%,.6);
    width: 100%;
    font-size: 1em;
    background-color: hsla(0,0%,100%,.1);
  }

  .food-editor__btn-wrapper {
    display: flex;
    padding: 5px 0;
    justify-content: space-between;
  }
}
