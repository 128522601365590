@import '../../../../../breakpoints';

.payment-list__cell{
  color: aliceblue;
  font-size: 1em;
  line-height: 1.6em;

  &--center {
    text-align: center;
  }
  @include _480 {
    font-size: 14px;
  }
  @include _428 {
    font-size: 11px;
  }
}

.payment-list__table-btn {
  min-width: 55px;
  padding: 5px;
}
