@import 'src/breakpoints';

.training-page {
  &__body-main {
    @media (max-width: 1200px) {
      width: 100%;
    }

    width: calc(100% - 460px);
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: right;

    @include _580 {
      flex-flow: column;
    }
  }

  &__header-arrows {
    display: inline-flex;
    align-items: center;

    .body-arrow {
      width: auto;

      &:first-of-type {
        margin-right: 0;
      }

      &--hidden {
        visibility: hidden;

        @include _428 {
          display: none;
        }
      }
    }
  }

  &__header-buttons {
    display: flex;
    flex-wrap: nowrap;
  }

  &__arrow-label {
    margin: 0 10px;
  }

  &__description {
    hr {
      margin-top: 0;
    }

    &-title {
      font-size: 1.4em;
      margin: 16px 0 8px 0;

      @include _480 {
        margin-left: 12px;
      }
    }

    &-body {
      resize: horizontal;
      color: hsla(0, 0%, 100%, .7);
      padding: 10px;
      border-radius: 10px;
      margin: 0;
      clear: right;
      background: rgba(0, 0, 0, .7);
      display: flex;
      flex-direction: column;

      p {
        margin: 0;
        white-space: pre-line;
      }

      @include _480 {
        padding: 12px 24px;
      }
    }

    &-text {
      &--truncated {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &-button {
      margin-top: 10px;
      align-self: flex-end;
      color: #fff;
      cursor: pointer;
    }
  }


  &__modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__modal-window {
    display: flex;
    align-items: center;
    flex-direction: column;

    outline: none;
    padding: 1.5rem;

    max-width: 25rem;

    border: 2px solid rgb(88, 166, 204);
    background: linear-gradient(rgb(9, 114, 165) 0%, rgb(1, 1, 3) 100%);
    border-radius: 1rem;

    font-size: 1.2rem;
    font-weight: 600;

    button {
      margin-top: 1.5rem;
      width: 100%;
    }
  }

  &__modal-title {
    text-align: center;
    margin-bottom: 1rem;
  }

  &__side {
    max-width: 460px;
  }

  .training-page__link.training-page__link--none-style {
    border-bottom: none;
    color: white;
    &:hover {
      border-bottom: 1px solid;
    }
  }
}
