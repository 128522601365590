.MuiAutocomplete-input {
  color: #24a3f4 !important;
  font-size: 1em !important;
  padding: 0 !important;
}

.MuiAutocomplete-listbox {
  color: #24a3f4 !important;
  background-color: black !important;
}

.MuiAutocomplete-option {
  &:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
}

.MuiAutocomplete-clearIndicator {
  display: none !important;
}
