@import '../../../breakpoints';

.list {
  width: 100%;
}
.table {
  width: 100%;
}

.list__table {
  border-radius: 10px;
  border-collapse: separate;
  border-spacing: 2px 9px;
  font-size: 0.9em;
  font-weight: 600;
  //padding: 2px 5px 2px 15px;
 // background: rgba(0, 0, 0, 0.7);

  thead {
    font-size: 1.1em;
    color: rgba(255, 255, 255, 1.0);

    tr {
      p {
        font-size: 1.1em;
        color: rgba(255, 255, 255, 1.0);
      }
    }
  }

  tbody {
    tr {
      td {
        a {
          color: rgba(255, 255, 255, 0.6) !important;
          border-bottom: none !important;
        }
      }
    }
  }
}


.list-arrow-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  .body-arrow{
    margin: 0;
    height: 25px;
    width: 25px;
  }
}

.list__virtual {
  font-weight: bold;

  @include _768 {
    font-size: 14px;
  }
  //@include _580 {
  //  font-size: 13px;
  //}
  //@include _480 {
  //  font-size: 12px;
  //}
  //@include _375 {
  //  font-size: 11px;
  //}
}
