.exercise-name {
  color: #35b5f4;
  cursor: pointer;

  &--active {
    color: hsla(0,0%,100%,.7);
  }

  &--disable {
    color: hsla(0,0%,100%,.7);
    cursor: auto;
  }
}
