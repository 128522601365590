@mixin flex-gap($gap, $flex-direction: row) {
  gap: $gap;

  & > * {
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
      &:not(:last-child) {
        @if $flex-direction == row {
          margin-right: $gap;
        } @else {
          margin-bottom: $gap;
        }
      }
    }
  }
}
