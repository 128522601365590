@import "../../breakpoints";

.delete-client {
  .delete-client &__title {
    margin: 0px 0px 15px 0px;
  }

  .delete-client &__form-wrapper {
    padding: 10px 10px 140px 10px;
  }

  .delete-client &__field-wrapper {
    padding: 7px 5px;
    width: 100%;
  }

  .delete-client &__label {
    color: #fff;
  }

  .delete-client &__input {
    border: none;
  }

  .delete-client &__btn {
    float: right;
    margin: 20px 5px 5px 5px;
    padding: 4px 10px;
  }

  .delete-client &__footer {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 10px 10px 70px 10px;
  }
}
