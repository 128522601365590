@import './src/_breakpoints.scss';

.mobile-drawer {
  background-color: rgba(0, 0, 0, .7);
  width: 500px !important;
  padding-top: 90px;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  // position: fixed;
  top: inherit;
  left: inherit;
  right: inherit;

  @include _480 {
    width: 100vw !important;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
  }

  @include _428 {
    display: flex;
    margin-top: 54px;
    padding: 0;
    // padding: 50px 0 0 0;
  }

  p {
    color: #aaaaaa;
  }

  .bodytest_text {
    color: #aaaaaa;
  }
}

.mobile-drawer::-webkit-scrollbar {
  width: 0;
}

.MuiDrawer-root {
  z-index: 100 !important; //0, если нужно все-таки прятать под header
}

.MuiPaper-root {
  //background-color: rgba(0, 0, 0, 1) !important;
  color: #fff !important;
  .side-closer {
    z-index: 100;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50px;
    right: 18px;
    cursor: pointer;
  }
}

.MuiDrawer-root--z-2 {
  z-index: 2 !important;
}
