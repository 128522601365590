@import './../../breakpoints';

.terms-privacy-form {
  top: 5%;
  left: 5%;
  max-width: 800px;
  span {
    display: block;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 20px;
    color: hsla(0,0%,100%,.9);
  }
}

.terms-privacy__title {
  margin-bottom: 20px;
  display: block;
  padding-top: 40px;
}

.content--terms-privacy-form {
  @include _768 {
    bottom: 60px;
  }
}

.content--terms-privacy-form .body-main .terms-link {
  border-bottom: none;
  color: #35b5f4;
  font-weight: bold;
}
