.invoice-details {
  &__header {
    margin: 0 0 15px 0;
  }

  &__table {
    border-collapse: separate;
    border-spacing: 2px 9px;
    font-size: 1.0em;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.65);
    padding: 15px 0 20px 15px;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    border-radius: 10px;

    &-invoice {
      font-size: 1.2em;
      border-bottom: 1px solid #fff;
      border-radius: 0;
    }

    &-trainers {
      thead {
        tr {
          td:nth-child(2) {
            width: 30%;
            text-align: center;
          }
        }
      }
      tbody {
        tr {
          .table-trainers {
            &__coach-name {
              padding-left: 10px;
              color: white;
            }
            &__client {
              &-name {
                padding-left: 10px;
                width: 70%;
              }
              &-active {
                width: 30%;
                text-align: center;
                color: #35b5f4;
              }
            }
          }
        }
      }
    }

    thead {
      tr {
        td {
          padding-bottom: 10px;
          font-size: 1.1em;
          color: rgba(255, 255, 255, 1.0);
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }
      }
    }

    tbody {
      tr {
        &:hover {
          cursor: pointer;
          background-color: rgba(255, 255, 255, .1);
        }
      }
      .table-trainers__footer {
        color: white;
        font-size: larger;

        td:nth-child(2) {
          width: 30%;
          text-align: center;
        }
      }
    }
  }
}
