.tools-block {
  .tools-block__input {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 1em;
  }

  &__form {
    border-radius: 10px;
    padding: 12px 12px 9px;
    background: rgba(0,0,0,.3);

    &:not(:last-child) {
      margin-bottom: 9px;
    }
  }

  &__form-title {
    font-size: 14px;
    margin-bottom: 12px;
    margin-left: 3px;
  }

  &__textarea {
    min-height: 125px;
    padding-left: 16px;
    resize: none;
    margin: 0;
  }

  &__input {
    border-radius: 200px;
    height: 24px;
    font-size: 12px;
    width: 100%;
    padding: 12px 10px 15px 20px;
    color: hsla(0,0%,100%,.9);
    background: hsla(0,0%,100%,.05);
    border: 0;
    outline: none;
    display: flex;

    &--sm {
      padding: 0;
      font-size: 14px;
      width: 30px;
      text-align: center;
    }
  }

  &__button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__button {
    font-size: 14px;
    margin: 5px;
    padding: 5px;
  }

  &__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;

    &--section {
      padding-right: 40px;
    }

    &--select {
      justify-content: flex-start;

      .tools-block__line-text {
        width: 132px;
      }
    }
  }

  &__change-section {
    display: flex;
    margin-bottom: 12px;
  }

  &__change-section-head {
    width: 132px;
  }

  &__change-section-grid {
    width: 180px;
  }

  &__change-section-line {
    width: 100%;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 3px;
    }

    span {
      margin-right: 43px;
    }

    input {
      margin-right: 7px;
    }
  }

  &__error {
    color: red;
    margin-bottom: 10px;
    font-weight: 700;
  }
}
