.save-diet__btn-wrapper {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 5px;
  color: white;
}

.save-diet__btn {
  margin-left: auto
}

.save-diet__form {
  color: white
}
