.diet-template {

  .diet-template__widget-body {
    padding: 0;
    overflow-y: auto;
    width: 100%;
    border-bottom-right-radius: 25px;
    height: calc(100% - 56px - 90px);
  }

  .diet-template__form {
    display: inline-block;
    width: 100%;
  }

  .foods-block {
    padding-bottom: 10px;
  }

  .diet-template__border {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin: 0 5px;
  }

  .field-food-label {
    color: rgb(255, 255, 255);
  }

  .diet-template__input-wrapper {
    padding: 7px 5px;
    width: 100%;
  }

  .confirm-delete {
    margin: 20px 5px 5px;
  }
}