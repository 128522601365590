.required-fields-warning {
  margin-top: 5px;
  padding: 0 5px 0 5px;
  display: flex;
  justify-content: flex-end;
  span {
    color: #aaaaaa;
    font-size: 1.1em;
  }
}
