@import "src/breakpoints";
@import "src/mixins";

.training-day {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    @include _480 {
      margin: 0 10px;
    }
  }

  &__title {
    font-size: 1.4em;
  }

  &__date {
    font-size: 18px;
  }

  &__date-text {
    margin-right: 4px;
  }

  &__new-pb {
    color: #35b5f4;
  }

  &__body {
    text-transform: lowercase;
    padding: 15px 25px;
    color: hsla(0,0%,100%,.7);
    font-size: 16px;
    cursor: pointer;
    min-height: auto;
    &-wrapper:not(:last-child) {
      margin-bottom: 10px;
    }

    &--opened {
      padding-top: 5px;
      padding-left: 10px;
      padding-right: 20px;

      @include _428 {
        font-size: 18px;
      }
    }

    &--disabled .pb-input {
      cursor: auto;
    }
  }

  &__workout-prefix {
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: 0.5rem;
  }

  &__body-header, &__exercise-row {
    display: grid;
    grid-template-columns: 75px 150px 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
    color: #fff;

    @media (max-width: 600px) {
      grid-template-columns: 75px 75px 1fr;
    }
  }

  &__body-header {
    @include _428 {
      display: none !important;
    }
  }

  &__exercise-rows {
    @include _428 {
      padding: 10px 20px;
    }
  }

  &__exercise-row {
    @include _428 {
      display: flex;
      flex-direction: column;
      @include flex-gap(5px, column);

      &__pb {
        order: 2;
        align-self: flex-start;
      }

      &__name {
        order: 1;
      }
    }
  }

  &__exercise-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    order: 3;
    @include flex-gap(10px);

    @include _428 {
      flex-direction: column;
      @include flex-gap(10px, column);
      flex-wrap: nowrap;
    }
  }

  &__notes {
    &-title {
      position: absolute;
      color: #aaa;
      font-weight: 600;
      font-size: 1.1em;
      margin: 8px 0 0 10px;
      text-transform: lowercase;
    }

    &-textarea {
      resize: none;
      padding: 5px 10px;
      text-indent: 6ch;
      line-height: 1.2;
      margin: 3px 0 10px 0;
      border: 0 solid #888;
      border-radius: 10px;
      color: #aaa;
      width: 100%;
      font-size: 1em;
      background-color: rgba(255, 255, 255, 0.05);
    }
  }

  &__note {
    margin-bottom: 1.5px;

    p {
      font-size: 1.3rem;
      @include _428 {
        font-size: 1.4rem;
        margin-left: 5px;
      }
      font-weight: 700;
      letter-spacing: 1px;
      margin-bottom: 5px;
    }

    span {
      @include _428 {
        font-size: 1.3rem;
      }
      white-space: pre-line;
      margin-left: 1.5rem;
      display: block;
    }
  }
}

.trainings-notes-widget-button {
  @include _428 {
    font-size: 1.2rem;
    padding: 3px 10px;
  }
}
