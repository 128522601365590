.diet__history {
  background: none;

  .diet-table {
    border-collapse: separate;
    border-spacing: 2px 9px;
    font-size: .9em;
    font-weight: 600;
    background: rgba(0, 0, 0, .7);
    border-radius: 10px;
    margin: 0 0 20px;
    padding: 2px 5px;
    overflow-x: auto;
    width: 100%;

    &thead,
    & thead tr td {
      font-size: 1.3rem;
      color: #fff;
    }

    & td {
      color: hsla(0, 0%, 100%, .65);
    }

    &__row--white td {
      color: #fff;
    }

    &__row--subheader td {
      padding-top: 20px;
      font-size: 1.2em;
    }
  }

  .diet__history-body {
    background: none;
    min-height: auto !important;
    padding: 0;
  }

  .white_tr {
    white-space: pre;
  }
}
