@import './src/_breakpoints.scss';

.history-item {

  @include _428 {
    padding: 5px;
    font-size: 1.17rem;
  }
  &--empty {
    color: hsla(0,0%,100%,.7);
    margin-left: 1em;
    margin-top: .2em;
    padding: 7px;
    &:last-child {
      margin-bottom: .2em;
    }
  }
}
