@import "../../constants";

.lean-content {
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  &__lean {
    @include _768 {
      justify-content: start;
    }
  }

  > * {
    font-size: 20px;
    color: hsla(0,0%,100%,.7);
    @include _428 {
      font-size: 14px;
    }
  }
}
