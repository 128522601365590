@import './../../../../../breakpoints.scss';

.day {
  color: #fff;
  margin-bottom: 20px;

  &__info {
    padding: 10px;
    background-color: rgba(0,0,0,.3);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: 1px solid hsla(0,0%,100%,.2);
  }

  &__icon-btn {
    padding: 0;
    margin: 0;
    font-size: 16px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    &--delete {
      color: red;
    }

    &--duplicate {
      color: #55b7f6;
    }

    &--add {
      color: #0b89da;
    }
  }

  &__header {
    display: flex;
    align-items: center;
  }

  &__header-text {
    font-size: 1.4em;
    margin-right: 10px;
  }

  &__delete-btn {
    margin-right: 5px;
  }

  &__add-workout-btn {
    font-size: 1.6em;
    padding-left: 30px;
    padding-top: 10px;
  }

  .workouts {
    padding-top: 10px;
  }

  .workout {
    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }

    &__header-text {
      font-size: 1.2em;
      margin-right: 10px;
    }

    &__delete-btn {
      margin-right: 5px;
    }

    &__exercises {
      display: grid;
      grid-gap: 9px;
      margin-bottom: 10px;
    }

    &__add-exercise-btn {
      padding-left: 40px;

      &--disable {
        color: hsla(0,0%,100%,.5);
      }
    }
  }

  .exercise-constructor {
    display: grid;
    grid-template-columns: 240px 1fr;

    &__delete-btn {
      margin-right: 5px;
    }

    &__duplicate-btn {
      margin-right: 5px;
    }

    &__name-text {
      color: #24a3f4;
      cursor: pointer;
    }

    &__name {
      display: flex;
      padding-left: 10%;
      align-items: center;
    }

    &__sets {
      display: grid;
      grid-template-columns: repeat(auto-fit, 150px);
      column-gap: 10px;
      row-gap: 9px;
    }

    &__add-set {
      display: flex;
      align-items: center;
      min-height: 27px;
      border-radius: 10px;
      padding: 4px 8px;
      background-color: #221e20;
      font-size: 12px;

      span {
        margin-right: 20px;
      }
    }
  }

  .exercise-set {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 27px;
    border-radius: 10px;
    padding: 4px 8px;
    background-color: #221e20;
    position: relative;
    font-size: 12px;

    &__input {
      width: 25px;
      background: transparent;
      text-align: center;
      color: #fff;
      border-style: none;
      outline: none;
      border-bottom: 1px solid #fff;
      font-size: 14px;
      padding: 0;

      &--reps {
        width: 30px;
        margin-right: 1px;
      }

      &:disabled {
        border-bottom: 1px solid transparent;
      }
      &:focus:not(:disabled),
      &:hover:not(:disabled) {
        color: #24a3f4;
        border-bottom: 1px solid #24a3f4;
      }

      &--error {
        border-bottom: 1px solid red;
      }
    }

    &__multiplier {
      display: inline-block;
      margin: 1px;
    }

    &__delete-btn {
      position: absolute;
      right: -5px;
      top: -7px;
    }
  }
}
