
.progress-widget {
  .progress-widget__body {
    @media screen and (max-width: 375px){
      padding: 0;
    }
  }

}

.progress-widget__arrow-wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 40px;
  .body-arrow {
    margin-right: 10px;
    width: unset;
    min-width: 40px;
    &--disabled {
      cursor: not-allowed;
    }
  }
  .body-arrow:nth-last-of-type(2) {
    margin-right: 15px;
  }
}

.progress-widget__tables-wrapper {
  display: block;
  justify-content: flex-start;
  padding-right: 10px;
}

.progress-widget__footnote {
  display: block;
  padding-left: 5px;
  color: rgba(255,255,255,0.8);
  font-size: 0.8em;
  font-weight: 600;
}
