@import '../../../breakpoints';

.list-search-wrapper{
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0 10px 10px;

  .search-input {
    margin: 0 0 2px;
  }
}

.list__cell--header {
  min-width: 20px;

  @include _480 {
    font-size: 14px;
  }
  @include _375 {
    font-size: 12px;
  }
}
