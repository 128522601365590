.search-input {
  -moz-appearance: textfield;
  float: right;
  margin: 0 5px 2px; //margin: 8px 0px 0px 0px;
  padding: 3px 5px;
  border: 2px solid transparent;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.6);
  width: 50%; //width: 100%;
  font-size: 1.2em;
  background: rgba(255, 255, 255, 0.1);//background-color: rgba(255, 255, 255, 0.1);

  &.right {
    text-align: right;
  }

  &:focus {
    border: 2px solid #3d7697;
    outline: none;
  }

  &:disabled {
    background-color: black;
    color: #aaaaaa !important;
  }
}

/*
.list-search {
  margin: 0 5px 2px;
  background: rgba(255, 255, 255, 0.1);
  float: right;
  width: 50%;
}*/