@import './../../../breakpoints';

.form-auth {
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  max-width: 400px;
  font-size: 15px;
  line-height: 1.42857143;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;

  input {
    font-size: 1.1em;
  }
}

.auth-btn {
  float: right;
  width: 100% !important;
  margin: 10.5px 0 0;
  height: 2.5em;
}

.auth-link-wrapper{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.auth-link{
  margin: 10.5px 0 0;
  color: #ccc;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}

.page-auth-img {
  width: 40%;
  min-width: 320px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.auth__error {
  color: red;
  font-weight: 800;
  text-shadow: 2px 0 1px black, -2px 0 1px black, 0 2px 1px black, 0 -2px 1px black;
  text-align: center;
  font-size: 20px;
}

.auth-main-block {
  padding-top: 22vh;
  min-height: 86vh;
  @include _480 {
    padding-top: 15vh;
  }
}

.auth-field {
  background-color: rgba(0,0,0,.6) !important;
  height: 40px
}

.forgot-password-tip {
  margin: 8px 0;
  color: #fff;
  font-size: 1.2em;
  font-weight: 800;
  text-align: center;
}

.auth-error {
  font-size: 1em;
  font-weight: 800;
  color: red;
}
