@import "./constants";
//.form-client-body-test {} не описаны

.body-comp-graph-wrap, .body-comp-graph-wrap-mobile {
  color: white;
  font-size: 14px;
  @include _428 {
    background: none;
  }
}

// Mobile version
.body-comp-graph-wrap-mobile {
  padding: 10px 0;
  font-size: 1.2em;

  .text-description {
    margin-right: 5px;
  }

  .body-comp-graph-col-wrap {
    margin-bottom: 40px;
  }

  .body-slider.strength-slider {
    bottom: $strengthMobileOffset;
    left: $bodyMobileHeight - 10;
    height: $strengthMobileHeight;
    max-width: calc(65% - 20px);

    .rc-slider-rail, .rc-slider-track, .rc-slider-step {
      height: $strengthMobileHeight;
    }

    .rc-slider-track {
      border-radius: 0px;
      background-color: #ccc;

      &:nth-child(2n) {
        background-color: #aaa;
      }
    }

    .rc-slider-track-3 {
      border-top-right-radius: $bodyBorderRadius;
      border-bottom-right-radius: $bodyBorderRadius;
    }
  }

  .button-controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    > button {
      margin-bottom: 5px;
    }
  }
}

.form-block {
  margin: 16px 0;

  button {
    @include _428 {
      margin: 5px 5px;
    }
  }
}

.text-centered { //в общее
  text-align: center;
}


.btn-body {
  font-family: 'Roboto', sans-serif;
  width: 10em;
  background: #1773a1;
  border-radius: 5px;
  border: 2px solid #5eb5e9;
  margin: 15px 15px;
  padding: 3px 10px;
  color: #fff;
  cursor: pointer;
  user-select: none;
  outline: none;
  transition: all 0.3s;
  white-space: pre;
  font-weight: 600;

  &:hover {
    background: #166590;
    border-color: #4b90b9;
  }

  &:active {
    background: #13597f;
    border-color: #3d7697;
  }

  &:disabled {
    background: #808080;
    color: #f1f1f1 !important;
    border-color: #f1f1f1;
    cursor: not-allowed;
  }
}
