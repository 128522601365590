@import "src/breakpoints";
@import "src/mixins";

.pb-input {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include _428 {
    flex-direction: row;
    height: 25px;

    &::before {
      content: "pb:";
      margin-right: .5ch;
      color: #fff;
    }
  }

  &__text {
    color: #35b5f4;
  }

  &__error {
    box-shadow: 0 0 9px rgb(255, 0, 0);
  }

  &__input {

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type=number] {
      -moz-appearance: textfield;
    }
    padding: 0;
    text-align: center;
    font-size: 18px;
    width: 100%;
    color: hsla(0,0%,100%,.9);
    background: hsla(0,0%,100%,.05);
    border-radius: 8px;
    border: none;
    outline: 1px solid transparent;

    @include _428 {
      width: 5em;
    }

    &:focus {
      outline: 1px solid #5eb5e9;
    }
  }

  &__button {
    width: 23px;
    height: 23px;
    padding: 0;
    min-width: 0;
    font-size: 14px;

    @include _428 {
      margin: 0 5px;
    }
  }
}
