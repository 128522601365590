@import "../../constants";

//rc-slider-wrap

.rc-slider-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    @include _428 {
      height: $mobileSmallHeight;
      margin: 0px ($mobileSmallHeight / 2);
    }
    .rc-slider-horizontal-value {
      font-size: 1.2em;
      white-space: nowrap;
      width: 25%;
    }
  }

.rc-slider {
    height: $totalHeight;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    padding: 5px 0;
    width: 100%;
    box-sizing: content-box;
    border-radius: 6px;
    touch-action: none;
    //-webkit-box-align: center;
    //-ms-flex-align: center;
    //align-items: center;
    margin-right: $totalHeight; //Выглядит как лишний отступ. Убрать?
    @include _428 {
      height: $mobileSmallHeight;
      margin-right: 0px;
    }
    .rc-slider-rail, .rc-slider-track, .rc-slider-step {
      height: $size;
      @include _428 {
        height: $mobileSmallHeight;
      }
    }
    .rc-slider-step {
      position: absolute;
      width: 100%;
      background: -webkit-linear-gradient(left, #000000 0%,#155b7d 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, #000000 0%,#155b7d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      border-radius: 20px;
      border: 2px solid #49afdf;
      transition: background 100ms ease-in, border 100ms ease-in;
      @include _428 {
        border: 1px solid #49afdf;
        border-radius: 10px;
      }
    }
    .rc-slider-track {
      background-color: transparent;
    }
    .rc-slider-handle {
      position: absolute;
      width: $totalHeight + 4;
      height: $totalHeight + 4;
      border-radius: 20px;
      border: 2px solid #49afdf;
      margin-top: -2px;
      margin-left: -$totalHeight / 2;
      background: white 50% 50% repeat-x;
      transition: background 100ms ease-in, border 100ms ease-in;
      @include _428 {
        width: $mobileSmallHeight + 3;
        height: $mobileSmallHeight + 3;
        border: 1px solid #49afdf;
      }
      &:hover {
        border: 2px solid #0b93d5;
        background: #003147 ;
        cursor: pointer;
      }
      &:focus {
        border: 2px solid #0b93d5;
        background: #003147 ;
      }
      &:active {
        border: 2px solid #26b3f7;
        background: #0972a5 50% 50% repeat-x;
      }

    }
    .rc-slider-mark-text {
      top: 10px;
    }
    .rc-slider-dot {
      width: 4px;
      height: 22px;
      border-color: #003147;
      border-radius: 0px;
    }
    .rc-slider-rail {
      position: absolute;
      width: 100%;
      background-color: #e9e9e9;
      border-radius: $borderRadius;
      border: 1px solid #555555;
      //background: #000000 url("../../../images/ui-bg_loop_25_000000_21x21.png") 50% 50% repeat;
    }
    &.rc-slider-disabled:not(.goal-slider__client) {
      opacity: unset;
      cursor: not-allowed;
      background-color: unset;
      transition: background 100ms ease-in, border 100ms ease-in;
      .rc-slider-step {
        background: -webkit-linear-gradient(left, #000000 0%,#808080 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #000000 0%,#808080 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        border-color: #f1f1f1;
      }
      .rc-slider-handle {
        position: absolute;
        border-color: #f1f1f1;
        &:hover {
          background: white;
          border-color: #f1f1f1;
        }
      }
    }
  }
