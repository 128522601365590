@import './../../../breakpoints.scss';

.client-progress-page__header {
    margin: 20px 0 0 ;
}

.client-progress-table {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-around;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding: 10px;
    overflow-x: scroll;
    @include _480 {
        padding: 0;
    }
}

.client-progress-page__arrow-wrapper {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom: 10px;
    height: 40px;
    width: 100%;

    .body-arrow {
        width: auto;
    }
}
