.float-left {
  float: left
}
.float-right {
  float: right;
}
.content-right {
  display: flex;
  justify-content: flex-end;
}
.content-vertical-center {
  display: flex;
  align-items: center;
}
.content-horizontal-center {
  display: flex;
  justify-content: center;
}
.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-center {
  text-align: center;
}
.border-radius-10 {
  border-radius: 10px;
}
.cursor-pointer {
  cursor: pointer;
}
.column-direction {
  flex-direction: column;
}
.underline-light {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.ellipsis {
  position: relative;
  &:before {
    content: '&nbsp;';
    visibility: hidden;
  }
  span {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
