@import './../../../breakpoints.scss';

.body__main {
  flex-grow: 1;
  padding-right: 20px;
  width: 66%;
  @include _1200  {
    width: 100%;
    padding-right: 0;
  }
}

.body__side {
  flex-grow: 1;
  padding-left: 20px;
  width: 33%;
  @include _1200  {
    width: 1%;
    padding-left: 0;
  }
}

.body__page-content {
  margin: -15px 0 0;
  position: relative;

  h1 {
    margin: 10px 0;
  }

  @include _480 {
    margin: 0 0 0;
    top: 15px;
  }
}

div.body__content-rhs {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  margin: 0 auto;
}

div.body__content {
  color: #fff;
  background: rgba(32, 32, 32, 0.6);
  border-radius: 25px;
  position: relative;
  transition: all 0.5s ease-in;
  padding: 20px;
  @include _480 {
    padding: 0;
  }
  @media only screen and (pointer: coarse) {
    margin-top: 5px;
    border-radius: 0;
  }
}
