@import "../../constants";

.goal-slider-wrap-table {
  padding: 20px 20px 0px 20px;
  width: 100%;
  font-size: 24px;
  color: rgba(255, 255, 255, 0.7);
  @include _580 {
    font-size: 16px;
  }
  @include _428 {
    padding: 5px;
    font-size: 16px;
  }
  &__row {
    display: flex;
    flex-direction: row;
    &-header{
      @include _580 {
        font-size: 16px;
      }
      color: rgba(255, 255, 255, 1);
      font-size: 20px;
    }
    padding-bottom: 9px;
    &:last-child {
      padding-bottom: 0px;
    }
  }
  &__cell-middle {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  &__cell-left {
    width: 25%;
    justify-content: flex-end;
    padding-right: 20px;
    @include _428 {
      padding-right: 5px;
    }
  }
  &__cell-right {
    width: 25%;
    padding-left: 20px;
    @include _480 {
      padding-left: 10px;
    }
    @include _428 {
      padding-left: 5px;
    }
    @include _375 {
      padding-left: 0;
    }
  }
  &__cell:not(&__cell-middle) {
    display: flex;
    align-items: center;
  }
}

.cell-left__title {
  margin-right: 15px;
}

.cell-right__title {
  justify-content: space-between;
  flex-flow: row wrap;
}

//-------кнопки-------
.goal-date-remove-button {
  height: 20px;
  width: 20px;
  padding: 0 0 0 5px;
  &:disabled {
    color: #ff434b !important;
    cursor: not-allowed;
  }
}

.goal-state-change__button {
  background: transparent;
  border: none;
  color: white;
  margin-right: 5px;
  padding: 0;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  &:focus {
    outline: none;
  }
  &:disabled {
    color: white !important;
    cursor: not-allowed;
  }
}


