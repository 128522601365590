@import '../../../breakpoints';

.profile__field {
  width: 99%;
  .form-input {
    width: 99%;
  }
}

.profile__row {
  display: flex;
  margin: 10px 0;
}

.profile__widget {
  min-height: 0 !important;
}

.profile__form {
  min-height: 0 !important;
  max-width: 870px;

  @include _1200 {
    max-width: none;
  }
}
