@import "../../constants";

.strength-content {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;

  > * {
    min-height: 50px;
    text-align: center;
    font-size: 20px;
    color: hsla(0,0%,100%,.7);
    font-family: Roboto,helvetica,sans-serif;
    @include _428 {
      font-size: 14px;
    }
    span {
      color: #fff;
    }
  }
}
