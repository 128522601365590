@import "../../variable";

.btn-old {
  font-family: $main-font-family;
  border-radius: $btn-border-radius;
  margin: $btn-margin;
  padding: $btn-padding;
  cursor: pointer;
  user-select: none;
  outline: none;
  transition: all 0.3s;
  font-size: 14px;
}
.btn-primary {
  min-width: 70px;
  background: $btn-primary-background-color;
  border: 2px solid $btn-primary-border-color;
  color: $btn-primary-font-color;
  &:hover {
    background: $btn-primary-hover-background-color;
    border-color: $btn-primary-hover-border-color;
  }
  &:active {
    background: $btn-primary-active-background-color;
    border-color: $btn-primary-active-border-color;
  }
  &:disabled {
    background: $btn-disabled-background-color;
    color: $btn-disabled-font-color !important;
    border-color: $btn-disabled-border-color;
    cursor: not-allowed;
  }
}
.btn-warning {
  min-width: 70px;
  background: $btn-warning-background-color;
  border: 2px solid $btn-warning-border-color;
  color: $btn-warning-font-color;
  &:hover {
    background: $btn-warning-hover-background-color;
    border-color: $btn-warning-hover-border-color;
  }
  &:active {
    background: $btn-warning-active-background-color;
    border-color: $btn-warning-active-border-color;
  }
  &:disabled {
    background: $btn-disabled-background-color;
    color: $btn-disabled-font-color !important;
    border-color: $btn-disabled-border-color;
    cursor: not-allowed;
  }
}
.btn-disabled {
  background: $btn-disabled-background-color;
  color: $btn-disabled-font-color !important;
  border-color: $btn-disabled-border-color;
  cursor: not-allowed;
}
.btn-icon-circle {
  width: 23px;
  height: 23px;
  padding: 0px !important;
  min-width: 0px !important;
}

.btn-grid {
  @media (max-width: 450px){
    width: 45%;
    float: none;
    white-space: normal;
  }
  @media (max-width: 240px){
    width: 100%;
    float: none;
  }
}
//Icon buttons
.program-control, .program-exercises-widget__exercise-favourite {
  margin-right: 10px;
  background: 0 0!important;
  color: #ff434b !important;
  text-shadow: 0 -1px 0 #CD0707,-1px 0 0 #CD0707,0 1px 0 #CD0707,1px 0 0 #CD0707!important;
  transition: all 0.2s ease-in;
  &:hover {
    cursor: pointer;
    color: #bf2200 !important;
    text-shadow: 0 -1px 0 #AA0101,-1px 0 0 #AA0101,0 1px 0 #AA0101,1px 0 0 #AA0101!important;
  }
  &:active {
    color: #9b0018 !important;
    text-shadow: 0 -1px 0 #d10101,-1px 0 0 #d10101,0 1px 0 #d10101,1px 0 0 #d10101 !important;
  }
}
