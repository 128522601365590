@import "../../../breakpoints";

.companies-page__header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;

  justify-content: space-between;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;

  align-items: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;

  @include _768 {
    margin-top: 0.625rem;
  }

  @include _480 {
    margin-top: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 12px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
  }
}

.companies-page__tab-buttons {
    position: absolute;
    top: -10px;
    width: 100%;
    text-align: center;
    z-index: 100;
}


.companies-page__body {
  border-radius: 10px;
  margin: 0 0 10px;
  padding: 0;
  font-family: Roboto, sans-serif;
  color: #fff;
  clear: right;
  background: rgba(0,0,0,.7);
}

.companies-page__table {
  margin: 0;
  padding: 0;
  width: 100%;
  border-collapse: collapse;

  tbody tr {
    height: 38px;

    &:nth-child(odd) {
        background: transparent url("../../../assets/images/content-group-back.png");
    }
  }

  .companies-page__table-header td{
    margin: 0;
    padding: 10px 10px;
    color: #FFF;
    background-color: rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &:first-child {
      border-top-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
    }

    @include _375 {
      font-size: 0.938rem;
    }
  }

  .companies-page__table-body {
    td {
      margin: 0;
      padding: 5px 5px 5px 10px;
      color: #FFF;
      font-size: 12px;
      line-height: 25px;
    }

    tr:hover td {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}
