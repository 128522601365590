.home__text {
  font-size: 1.8em;
  margin: .67em 0;
  color: #fff;
  font-family: Roboto,sans-serif;
}

.home__wrapper {
  padding-top: 50px!important;
}
