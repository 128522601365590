.companies-table__item td .redirect-btn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  overflow: visible;
  margin-right: .1em;
  outline: none;
  border: 1px solid #444;
  border-radius: 6px;
  padding: .4em 2.1em .4em 1em;
  height: 28px;
  line-height: 1.3;
  background: #222 url("../../../../../../../assets/images/ui-bg_highlight-soft_35_222222_1x100.png") 50% 50% repeat-x;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
  color: #fff;
  font-family: Verdana,Arial,sans-serif;
  font-size: 12px;
  zoom: 1;
  min-height: 26px;
  @media screen and (max-width: 1400px) {
    margin: 0;
  }
}

.redirect-btn:not(:disabled):not(:active):hover {
    border: 1px solid #0b93d5;
    background: #003147; //url("../../../../../../../assets/images/ui-bg_highlight-soft_33_003147_1x100.png") 50% 50% repeat-x;
    font-weight: 400;
    color: #fff;
}


.redirect-btn__icon {
  width: 16px;
  height: 16px;
  background-image: url("../../../../../../../assets/images/ui-icons_cccccc_256x240.png");
  background-position: -48px -192px;
  position: absolute;
  top: 50%;
  margin-top: -8px;
  right: 0.5em;
}
