.invoice-per-month {
  &__header {
    margin: 0 0 15px 0;
  }

  &__table {
    border-collapse: separate;
    border-spacing: 2px 9px;
    font-size: 1.0em;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.65);
    padding: 15px 0 20px 15px;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    border-radius: 10px;

    thead {
      tr {
        td {
          padding-bottom: 10px;
          font-size: 1.1em;
          color: rgba(255, 255, 255, 1.0);
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }

        td:nth-child(2) {
          width: 30%;
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        &:hover {
          cursor: pointer;
          background-color: rgba(255, 255, 255, .1);
        }

        .invoice-per-month__info {
          width: 70%;
          padding-left: 10px;
          color: #35b5f4;
        }

        .invoice-per-month__status {
          width: 30%;
          text-align: center;
        }
      }
    }
  }
}
