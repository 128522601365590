.slider-date__goal-state-wrapper {
  display: flex;
  flex-direction: row;
  span {
    white-space: nowrap;
  }

  .slider__date--mobile {
    font-size: 12px;
  }

  .slider__date--desktop {
    font-size: 20px;
  }

  .slider__date {
    white-space: pre;
  }

  .slider-date__btn {
    margin-left: 0
  }
}
