@import './../../../breakpoints.scss';

.body-main {
  flex-grow: 1;
  padding-right: 20px;
  width: 66%;
  min-height: 80vh;
  @include _1200 {
    width: 100%;
    padding-right: 0;
  }
}

.body-side {
  flex-grow: 1;
  padding-left: 20px;
  width: 33%;
  @include _1200 {
    width: 1%;
    padding-left: 0;
  }
}

div.content-rhs {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  margin: 0 auto;
}

div.content {
  color: #fff;
  background: rgba(32, 32, 32, 0.6);
  border-radius: 25px;
  position: relative;
  transition: all 0.5s ease-in;
  padding: 20px;
  @include _480 {
    padding: 0;
  }
  @media only screen and (pointer: coarse) {
    margin-top: 5px;
    border-radius: 0;
  }
}

.left-widget__wrapper-test-result {
  width: 100%;
}

.client-body-result__footer-btns {
  border-radius: 5px;
  width: 140px;
  color: #fff !important;
}
