@import "./src/breakpoints";

.form-input {
  -moz-appearance: textfield;
  padding: 5px;
  margin: 8px 0px 0px 0px;
  border: 2px solid transparent;
  border-radius: 10px;
  color: rgba(255, 255, 255, 0.6);
  width: 100%;
  font-size: 1em;
  background-color: rgba(255, 255, 255, 0.1);

  &.right {
    text-align: right;
  }

  &:focus {
    border: 2px solid #3d7697;
    outline: none;
  }

  &:disabled {
    background-color: black;
    color: #aaaaaa !important;
  }

  option {
    background: #000000;
  }
}

.text-info {
  float: right;
  font-size: 0.8em;
  padding-top: 4px;
  color: white;
}

.field-error {
  border: 2px solid #FF0000 !important;
  background-color: #ebcccc !important;
  color: #eb4545 !important;
}

.form-select {
  border: 0;
  padding: 8px;
  cursor: pointer;
  color: #24a3f4 !important;
  border-radius: 10px;
  font-size: 1em;
  -webkit-appearance: none !important;
  background-color: rgba(255, 255, 255, 0.1);
  outline: none;
  margin: 8px 0px 0px 0px;
  text-transform: lowercase;

  &:focus {
    outline: 2px solid #3d7697;
  }

  option {
    text-transform: lowercase;
    cursor: pointer;
    color: #24a3f4;
    background: #000000 !important;

    &:disabled {
      color: rgba(255, 255, 255, 0.6);
    }
  }

  //.Select-value-icon.Select-value-icon {
  //  color: red;
  //  border: 0;
  //  border-radius: 0;
  //  font-size: 18px;
  //  font-weight: 600;
  //  padding: 0 5px;
  //  position: relative;
  //  top: -1px;
  //}

  //.Select-value.Select-value {
  //  color: white;
  //  display: inline-block;
  //  margin-left: 0;
  //  margin-top: 5px;
  //  vertical-align: middle;
  //  padding: 5px;
  //  border: 0;
  //}
}

.inline-field {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.body-test-select {
  height: 30px;
  border: 0;
  color: rgba(255, 255, 255, 0.6) !important;
  cursor: pointer;
  border-radius: 10px;
  font-size: 1em;
  -webkit-appearance: none !important;
  background-color: rgba(255, 255, 255, 0.1);
  outline: none;
  margin: 8px 0px 0px 0px;
  text-transform: lowercase;

  option {
    text-transform: lowercase;
  }

  &:focus {
    border: 2px solid #3d7697;
    outline: none;
  }

  option {
    cursor: pointer;
    background: #000000 !important;
  }

  .Select-control{
    min-width: unset;
  }
}

.input-without-border {
  .form-input {
    border: 0 solid #888;
  }
}

.textarea {
  &-resize-none {
    resize: none;
  }
  &-resize-vertical {
    resize: vertical;
  }
}

.sublabel {
  color: #888;
  font-size: 16px;

  @include _428 {
    color: #aaa;
    font-size: 14px;
  }
  @include _375 {
    font-size: 12px;
  }
}
