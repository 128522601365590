@import './../../../breakpoints.scss';

.exercises__main {
  flex-grow: 1;
  padding-right: 20px;
  width: 66%;
  color: hsla(0, 0%, 100%, .65);

  @include _1200 {
    width: 100%;
    padding-right: 0;
  }
}

.exercises__side {
  flex-grow: 1;
  padding-left: 20px;
  position: fixed;
  right: 45px;
  width: 33%;

  @include _1200 {
    position: static;
    width: 1%;
    padding-left: 0;
  }
}

.exercises__page-content {
  margin: -15px 0 0;
  position: relative;
  width: 66%;
  display: flex;
  box-sizing: border-box;

  h1 {
    margin: 10px 0;
  }

  @include _480 {
    margin: 0;
    h1 {
      margin-top: 0;
    }
  }

  @include _1200 {
    width: 100%;
  }
}

div.exercises__content {
  color: #fff;
  background: rgba(32, 32, 32, 0.6);
  border-radius: 25px;
  position: relative;
  transition: all 0.5s ease-in;
  padding: 20px;

  @include _480 {
    padding: 0;
  }

  @media only screen and (pointer: coarse) {
    margin-top: 5px;
    border-radius: 0;
  }
}
