@import "../../_colors";
@import "../../_font";
@import './src/_breakpoints.scss';

.btn {
  font-family: $main-font-family;
  border-radius: 15px;
  margin: 5px;
  padding: 3px 10px;
  min-width: 70px;
  cursor: pointer;
  user-select: none;
  outline: none;
  transition: all 0.3s;
  /*font-size: 14px;*/
  font-size: 100%; //
  line-height: 1.15; //

  @include _428 {
    border-radius: 20px;
  }

  &:disabled {
    cursor: not-allowed;
    background: $btn-disabled-background-color !important;
    color: $btn-disabled-font-color !important;
    border-color: $btn-disabled-border-color !important;
  }
}

.btn--primary {
  background: $btn-primary-background-color;
  border: 2px solid $btn-primary-border-color;
  color: $btn-primary-font-color;
  &:hover {
    background: $btn-primary-hover-background-color;
    border-color: $btn-primary-hover-border-color;
  }
  &:active {
    background: $btn-primary-active-background-color;
    border-color: $btn-primary-active-border-color;
  }
}

.btn--warning {
  background: $btn-warning-background-color;
  border: 2px solid $btn-warning-border-color;
  color: $btn-warning-font-color;
  &:hover {
    background: $btn-warning-hover-background-color;
    border-color: $btn-warning-hover-border-color;
  }
  &:active {
    background: $btn-warning-active-background-color;
    border-color: $btn-warning-active-border-color;
  }
}
