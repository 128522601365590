@import "src/breakpoints";
@import "src/mixins";

.set-button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 50px;
  min-width: 120px;
  padding: 5px;
  color: #fff;
  border-radius: 10px;
  background-color: hsla(0, 0%, 78%, .1);
  font-size: .9em;
  cursor: pointer;
  transition: all .2s ease-in;

  @include _428 {
    font-weight: bold;
    min-height: 36px;
    margin: 0 10px 0 20px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 2ch;
    @include flex-gap(1ch);

    &:first-of-type {
      margin-top: 10px;
    }
  }

  &__idx {
    display: none;

    @include _428 {
      color: #fff !important;
      display: inline;
    }
  }

  &__main {
    text-align: center;
  }

  &__addition {
    color: hsla(0, 0%, 100%, .7);

    $parent: &;

    &--small {
      @extend #{$parent};
      font-size: .9em;

      @include _428 {
        font-size: 1em;
      }
    }
  }

  &--active {
    box-shadow: 0 0 20px 0 rgba(94, 181, 233, 0.7);
  }

  &--new-pb {
    * {
      color: #35b5f4;
    }
  }

  &:hover {
    box-shadow: 0 0 20px 0 rgba(94, 181, 233, 0.7);
    background-color: hsla(0, 0%, 78%, .2);
  }

  &--disabled {
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
    }
  }

  &__complete-icon {
    position: absolute;
    top: -6px;
    right: 0;
  }

  &__value, &__weight, &__reps {
    font-size: 1.2em;

    &--big {
      font-size: 1.4em;
    }
  }

  &__value {
    @include _428 {
      font-size: 1em;

      &--big {
        font-size: 1.2em;
      }
    }
  }

  &__units {
    margin-left: 2px;
  }

  &__multiply {
    margin: 0 5px;
  }
}
