@import "../../constants";

.body-comp-graph-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;

  min-width: 115px; // theoretically a bit more than maximum width in mobile version

  h1, h2, h3, h4, h5 {
    margin: 0;
  }

  h2 {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.7);
    @include _428 {
      font-size: 14px;
    }
  }

  .text-description {
    font-size: 22px;
    color: #fff;
    @include _428 {
      font-size: 16px;
    }
  }

  > div {
    margin-bottom: 30px;
    white-space: nowrap;
    @include _428 {
      margin-bottom: 20px;
    }
  }
}

.flex-text-centered {
  margin: auto;
}

.flex-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;

  .text-description {
    font-size: 20px;
    color: #fff;
    @include _428 {
      font-size: 14px;
    }
  }
}
