@import "../../../breakpoints";

.checkbox-list {
  @include _480 {
    justify-content: flex-end;
    flex-flow: column;
  }
}

.header-btn {
  @include _480 {
    justify-content: flex-end;
    flex-flow: column;
  }

  button {
    width: 115px;
  }
}
