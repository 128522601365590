@import "../../constants";

.graph {
  width: 100%;
  -webkit-border-radius: $bodyBorderRadius;
  -moz-border-radius: $bodyBorderRadius;
  border-radius: $bodyBorderRadius;
  padding: 0;
  color: white;
  border: 2px solid #58a6cc;

  .strength-slider > & {
    min-height: 150px;
	}

  @include _768 {
    border: 1px solid #58a6cc;
    border-radius: $bodyBorderRadius / 2;
  }
}

.graph__main {
  background: linear-gradient(0deg,#0972a5,rgba(0,0,0,.3));
  border-radius: $bodyBorderRadius - 2;
  @include _768 {
    border-radius: $bodyBorderRadius / 2 - 2;
  }
}

.graph__sub {
  background: linear-gradient(180deg,#1e2126,#010103);
  border-radius: $bodyBorderRadius;
  border-bottom: 2px solid #58a6cc;
  min-height: 25px;

  @include _768 {
    border-bottom: 1px solid #58a6cc;
    border-radius: $bodyBorderRadius / 2;
  }
}
