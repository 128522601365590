.max-unit {
  position: absolute;
  right: .5em;
  padding-top: 0;
  font-size: 1em;
}

.max-value {
  position: relative;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 5px;
  border: 0 solid #888;
  border-radius: 10px;
  color: hsla(0,0%,100%,.8);
  font-size: 1em;
  background-color: hsla(0,0%,100%,.1);

  &--error {
    border: 2px solid #f00 !important;
    background-color: #ebcccc !important;
    color: #f00 !important;
    font-weight: bold;
  }
}

.strength-test-btn {
  width: 5em !important;
  margin-right: 0 !important;
  float: right !important;
}

.strength-test__form__label {
  color: #ffffff;
}

.strength-test__form {
  margin: 5px 8px;
}

