.add-trainer {
  padding: 30px 10px 140px;
}

.invoice-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  button {
    margin-top: 2rem;
    align-self: flex-end;
    text-align: center;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"],
  input[type="number"]:hover,
  input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
  }
}

.payment-page__container {
  width: 100%;
}

.payment-page__content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.payment-page__search-container {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.invoice-form__row {
  color: hsla(0,0%,100%,.65);
  font-size: 1.1em;
  line-height: 1.6em;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.invoice-form__amount {
  margin-top: 30px;
}

.invoice-form__input{
  text-align: right;
  width: 50%;
  margin-top: 0;
}

.invoice-form__button {
  float: right;
  padding: 5px;
  min-width: 98px;
}

.invoice-sent {
  min-height: fit-content;
}
