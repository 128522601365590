@import "../../breakpoints";

.default-btn {
  width: auto;

  @include _428 {
    width: 45%;
  }
}

.invoice-maximum-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;

  input {
    margin-top: unset;
    max-width: 150px;
    width: unset;
    margin-left: 10px;
  }
}

.company-form-checkbox {
  margin-left: 10px;
  margin-right: 15px;
}
