@import "../../../../../../../../../../breakpoints";

.goal-date-input {
  -moz-appearance: textfield;
  font-family: 'Roboto', sans-serif;
  padding: 0px 0px 0px 5px;
  border: 0px solid #888;
  border-radius: 5px;
  color: white;
  width: 110px;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.0);

  &:focus {
    border: 0px solid #3d7697;
    outline: none;
  }

  &:disabled {
    color: white !important;
    cursor: not-allowed;
  }
}

.goal-date-input--mobile {
  font-size: 16px;
  max-width: 85px;

  @include _580 {
    font-size: 14px;
    max-width: 65px;
  }

  @include _480 {
    font-size: 12px;
    max-width: 65px;
  }

  @include _428 {
    font-size: 11px;
    max-width: 49px;
  }
}

.goal-date-input--error {
  &:not(:focus) {
    outline: 2px solid #FF0000 !important;
    background-color: #ebcccc !important;
    color: #eb4545 !important;
  }
}
