.widget-head {
  border-bottom: 1px solid #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  overflow: auto;
  &__title {
    margin-bottom: 5px;
    margin-top: 5px;
    margin-left: 10px;
  }
  &__controls {
    display: flex;
    align-items: center;
  }
}
.sub-widget-head {
  font-size: 14px;
  color: white;
  padding: 0px 0px 12px 3px;
}
