@import '../../../breakpoints.scss';

.widget-body {
  position: relative;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px; //вынести в отдельный селектор (!)
  border-radius: 10px;
  width: 100%;
  @include _428{
    padding: 0;
  }

  &--with-mh {
    min-height: 71vh;
  }
}

.widget-body--pb-50 {
  padding-bottom: 50px;
}
