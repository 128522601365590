.diet__meals {
  background: none;

  .diet-table {
    border-collapse: separate;
    border-spacing: 2px 9px;
    font-size: .9em;
    font-weight: 600;
    background: rgba(0, 0, 0, .7);
    border-radius: 10px;
    margin: 0 0 20px;
    padding: 2px 5px;
    overflow-x: auto;
    width: 100%;

    &thead,
    & thead tr td {
      font-size: 1.1em;
      color: #fff;
    }

    & tr {
      margin: 20px;
    }

    & td {
      color: hsla(0, 0%, 100%, .65);
    }
  }

  .diet__meals-body {
    padding: 0;
    background: none;
    min-height: auto !important;
  }

  .white_tr {
    white-space: pre;
  }
}
