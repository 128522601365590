.disabled-field { //foods-input :disabled
  -moz-appearance: textfield;
  padding: 5px;
  margin: 8px 0px 0px 0px;
  border: 2px solid transparent;
  border-radius: 10px;
  width: 100%;
  font-size: 1em;
  background-color: black;
  color: #aaaaaa !important;
}