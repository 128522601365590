.table {
  padding: 5px;
}
.row {
  width: 100%;
  padding-bottom: 1em;
  display: flex;
}
.column {
  padding-left: 10px;
  padding-right: 10px;
  &_editable {
    cursor: pointer;
  }
}

$column-width: (1,2,3,4,5,6,7,8,9,10,11,12);

@each $multiply in $column-width {
  .column-#{$multiply} {
    display: flex;
    width: ((100 / 12) * $multiply) * 1%
  }
}
