.trainer-form {
  &__field {
    padding: 10px 5px;
    
    .form-label, .inline-field {
      color: #fff;
    }
  }

  &__field--horizontal {
    margin-left: 0.625rem;
    width: calc(100% - 0.625rem)
  }

  .inline-field {
    padding: 10px 5px;
  }


  &__button-wrapper {
    display: flex;
    margin-top: 0.938rem;

    &--one-button {
      justify-content: flex-end;
    }

    &--two-buttons {
      justify-content: space-between;
    }
  }
}
