.body-charts {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 75px;
  margin-top: 15px;
}

.body-charts > * {
  justify-content: center;
  display: flex;
  flex-grow: 1;
}

.body-charts > *:first-child {
  justify-content: flex-end;
}

.body-charts > *:nth-child(3) {
  justify-content: start;
}
