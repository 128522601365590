@import '../../../breakpoints';

.diet {
  .widget-body {
    padding-bottom: 10px;
  }

  &__main {
    flex-grow: 1;
    padding-right: 20px;
    width: 66%;

    @include _1200 {
      width: 100%;
      padding-right: 0;
    }
  }

  &__side {
    flex-grow: 1;
    padding-left: 20px;
    width: 33%;

    @include _1200 {
      width: 1%;
      padding-left: 0;
    }
  }

  &__body {
    display: flex;
    align-items: stretch;
    padding-bottom: 10px;
  }


  &__table-wrapper {
    min-height: auto;
    padding: 0;

    @include _1200 {
      width: 100%;
    }
  }

  &__meals-wrapper {
    flex: 1 1 29%;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    margin-left: 1%;
    min-height: auto;
    height: calc(100% - 30px);
    padding: 0 0 3px 0 !important;
  }

  &__table-wrapper {
    background: unset !important;
  }


  &__widget-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
  }

  &__widget {
    position: sticky;
    top: 55px;
  }


  &__notes {
    textarea {
      //max-width: 0 0 10px 0;
      width: 100%;
      color: #ffffff;
    }
  }

  &__input {
    padding: 5px;
    margin-bottom: 10px;
    border: 0 solid #888;
    border-radius: 10px;
    color: hsla(0, 0%, 100%, .8);
    width: 100%;
    font-size: 1em;
    background-color: rgba(0, 0, 0, .7);
  }

  &__header {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  &__header-btn-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.diet-meals {
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  &-body {
    .diet-table {
      background: unset !important;
      width: 95%;
      margin: auto;
    }
  }

  &-header {
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 1.3rem;
      color: white;
      font-weight: 100;
      line-height: 30px;
    }
  }
}

.diet-table {
}

.body__page-content {
  margin: -15px 0 0;
  position: relative;

  h1 {
    margin: 10px 0;
  }

  @media (max-width: 500px) {
    margin: 0 0 0;
    top: 15px;
  }
}

div.body__content-rhs {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  margin: 0 auto;
}

div.body__content {
  color: #fff;
  background: rgba(32, 32, 32, 0.6);
  border-radius: 25px;
  position: relative;
  transition: all 0.5s ease-in;
  padding: 20px;

  @media (max-width: 500px) {
    padding: 0;
  }

  @media only screen and (pointer: coarse) {
    margin-top: 5px;
    border-radius: 0;
  }
}

.diet-table {
  border-collapse: separate;
  border-spacing: 2px 9px;
  font-size: 0.9em;
  font-weight: 600;
  border-radius: 10px;
  margin: 0 0 20px;
  padding: 2px 5px 2px 5px;
  overflow-x: auto;
  flex-grow: 1;
  align-self: stretch;
  background: rgba(0, 0, 0, 0.7) !important;

  &__wrapper {
    flex-grow: 1;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  thead {
    font-size: 1.1em;
    color: rgba(255, 255, 255, 1.0);

    tr {
      td {
        font-size: 1.1em;
        color: rgba(255, 255, 255, 1.0);
      }
    }
  }

  td {
    color: rgba(255, 255, 255, 0.65);
  }

  &__td {
    border-top: 1px solid rgba(255, 255, 255, 0.65);

    &--noborder {
      border: none;
    }

    &--color {
      outline: 2px solid red;
    }

    &__total-rows {
      color: rgb(255, 255, 255) !important;
    }
  }
}

.diet-tab-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  margin-right: 10px;
  padding: 10px 15px;
  color: white;
  font-size: 1.3rem;
  font-weight: 100;
  line-height: 30px;
  cursor: pointer;
  min-height: 56px;
  max-width: 50%;

  &--mobile {
    max-width: unset;
    flex-flow: row wrap;

    .d-flex {
      justify-content: center;
      align-items: center;
      width: 200px;
    }
  }

  &--unactive {
    opacity: 0.7;

    .d-flex {
      justify-content: unset;
      align-items: unset;
      width: unset;
      min-width: unset;
    }
  }

  &__item {
    height: 36px;
    display: flex;
    align-items: center;

    span {
      white-space: pre;
    }
  }
}

.diet-for-days-div {
  max-width: 200px;
}


.diet-for-days-select {
  cursor: pointer;
  border-radius: 10px;
  height: 36px;

  &.is-disabled {
    div {
      background-color: transparent !important;
    }
  }

  &.for-days-mobile {
    span {
      font-size: 1.2rem;
    }
  }

  div {
    color: rgba(255, 255, 255, 0.6);
    min-width: unset;

    span div {
      display: flex;
      justify-content: center;

      span {
        color: rgba(255, 255, 255, 0.6);
        font-size: 1.3rem;
        font-weight: 100;
        line-height: 30px;
        display: flex;
        align-items: center;
      }
    }
  }
}

.MuiPopover-root .MuiPaper-root {
  background-color: #000 !important;
  border: 1px solid #646464 !important;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06) !important;
  max-height: 200px;
  border-radius: 10px !important;

  /* width */
  ::-webkit-scrollbar {
    width: 6px;
    border-radius: 3px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.2);
    height: 69px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  ul {
    border-radius: 10px;

    .Mui-selected {
      background-color: rgba(36, 163, 244, .2);
      color: #24a3f4;
    }

    li {
      font-size: 1.2rem;
      border-radius: 0;
      color: hsla(0, 0%, 100%, .6);
      min-width: unset;

      &:hover {
        background-color: hsla(0, 0%, 100%, .1);
        color: #24a3f4;
      }

      &:first-child {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      &:last-child {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
}

.deleted_food {
  color: rgb(85, 85, 85) !important;
}

.program-control__fav {
  color: #000;
  width: 14px;
  height: 16px;
  margin-left: 3px;
}
