@import 'src/_breakpoints.scss';

.performance-widget {
  &__arrow-wrapper {
    .body-arrow {
      width: auto;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @include _428 {
      margin-top: 10px;
      justify-content: space-around;

      .btn {
        font-size: 1.2rem;
        width:100px;
        border-radius: 20px;
        padding: 5px 10px;
      }
    }
  }

  &__body {
    @include _428 {
      min-height: calc(100% + 40vh);
    }
  }

  &__notes {
    margin-top: 10px;

    .notes-input {
      width: 100%;
      padding: 8px 10px 8px 25px;
      color: hsla(0,0%,100%,.9);
      background: hsla(0,0%,100%,.05);
      border-radius: 8px;
      border: 0;
      outline: none;
      font-size: 14px;

      @include _428 {
        font-size: 1.2rem;
      }
    }

    @include _428 {
      font-size: 1.5rem;
    }
  }

  &__notes-row {
    margin-top: 5px;
    display: flex;
  }

  &__subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 5px;
    font-size: 1.3em;
    @include _428{
      font-size: 1.2em;
      padding: 17px 2px 12px 8px;
      .btn {
        font-size: 1.2rem;
      }
    }
  }

  &__drums {
    min-height: 0;
    padding: 10px 0;
    @include _428 {
      font-size: 1.4rem;
    }
  }

  &__check-icon {
    margin-left: 5px;
  }
}



