$black: #000;
$white: #fff;
$primary: #24a3f4;
$input-label: #a7a9a9;

$content-block-color: rgba(0, 0, 0, 0.3);

//BUTTONS
//-primary
//--main
$btn-primary-background-color: #1773a1;
$btn-primary-border-color: #5eb5e9;
$btn-primary-font-color: $white;
//--hover
$btn-primary-hover-background-color: #166590;
$btn-primary-hover-border-color: #4b90b9;
//--active
$btn-primary-active-background-color: #13597f;
$btn-primary-active-border-color: #3d7697;
//-disabled
$btn-disabled-background-color: #808080;
$btn-disabled-border-color: #f1f1f1; //$white;
$btn-disabled-font-color: #f1f1f1; //$white;
//-warning
//--main
$btn-warning-background-color: #f03802;
$btn-warning-border-color: #f03802;
$btn-warning-font-color: $white;
//--hover
$btn-warning-hover-background-color: #c81000;;
$btn-warning-hover-border-color: #f03802;
//--active
$btn-warning-active-background-color: #961000; //#8b0b00;
$btn-warning-active-border-color: #f03802;
//--disabled