@import "../../../../../colors";
@import "../../../../../font";
@import "../../../../../breakpoints";

$size: 18px;
$totalHeight: $size;
$mobileSmallHeight: 9px;

$borderRadius: 6px;
$bodyBorderRadius: 20px;

$bodyWidth: 118px;

$mobileSmallBodyWidth: 50px;
$mobileMediumBodyWidth: 65px;
$mobileLargeBodyWidth: 70px;

$strengthWidth: $bodyWidth;
$strengthOffset: 20px;

$strengthMobileHeight: 30px;
$strengthMobileOffset: -3px;
$bodyMobileHeight: 50px;
