.table-tooltip {
  &.rc-slider-tooltip-placement-top {
    padding: 4px 0 8px 0;
  }

  div {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .rc-slider-tooltip-inner {
    font-size: 11px;
    color: #fff;
    text-shadow: 0 0 2px #000;
    padding: 4px 8px;
    border: 1px solid rgba(255, 255, 255, 0.25);
    background-color: rgba(0, 0, 0, 0.9);
    background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(transparent), to(#000));
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    box-shadow: 0 0 3px #555;
    -webkit-box-shadow: 0 0 3px #555;
    -moz-box-shadow: 0 0 3px #555;
    max-width: 300px;
    height: unset;
    word-wrap: anywhere;


    span {
      color: #ffffff;
      font-size: 15px;
    }
  }

  .rc-slider-tooltip-arrow {
    bottom: 4px !important;
    left: 50%;
    margin-left: -4px;
    border-width: 4px 4px 0;
    border-color: #6c6c6c transparent transparent !important;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  z-index: 1300 !important;
}
