@import "./src/breakpoints";

.history-widget {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include _428 {
      .btn {
        font-size: 1.2rem;
        padding: 3px 10px;
      }
    }
  }

  &__body {
    min-height: 0;
    @include _428 {
      padding: 5px;
    }
  }

  &__headings {
    display: grid;
    grid-template-columns: 1fr 45px 25px 40px 25px 45px;
    padding: 10px 25px;

    @include _428 {
      font-size: 1.3rem;
    }
  }

  &__headings-title {
    text-align: left;
  }

  &__headings-item {
    text-align: center;
  }

  &__btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__btn {
    cursor: pointer;
    color: #fff;
    background: none;
    border: none;
    padding: 5px;
    outline: none;
    font-family: 'Roboto', sans-serif;
  }
}

.history-widget-details {
  padding: 15px;
  @include _428 {
    padding: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 1px;
    text-transform: lowercase;
    margin-bottom: 20px;

    @include _428 {
      padding: 5px;
      font-size: 1.5rem;
    }
  }
}

.history-details-item {
  @include _428 {
    font-size: 1.2rem;
  }
  &__header {
    color: rgba(255, 255, 255, 0.7);
    margin: 5px 0;

    &--highlighted {
      color: #fff;
      font-weight: bold;
    }
  }
}

.history-details-row {
  display: grid;
  grid-template-columns: 20% 40% 1fr;
  color: rgba(255, 255, 255, 0.7);
  padding: 5px 5px 5px 15px;
  line-height: 1.15;

  &--highlight {
    color: white;
    font-weight: bold;
    background: rgba(200, 200, 200, 0.1);
    padding: 15px 15px 15px 25px;
    font-size: 1em;
    border-radius: 10px;
    @include _428 {
      padding: 10px 20px;
    }
  }

  &__max {
    text-align: right;
  }

  &__notes {
    grid-column: 1 / 4;
    margin-top: .3em;
    font-size: .8em;
  }
}
