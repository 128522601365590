@import './../../../breakpoints.scss';

.progress__table-content {
  text-rendering: optimizeLegibility;
  border-collapse: separate;
  justify-content: space-between;
  border-spacing: 2px 9px;
  font-size: 0.9em;
  text-align: right;
  font-weight: 600;
  margin: 0 0 5px 0;
  @include _480 {
    font-size: 0.8em;
  }

  thead {
    tr {
      td {
        div {
          display: block;
          height: 2em;
          padding-top: 8px;
          font-size: 1.1em;
        }
      }
    }
  }

  tbody {
    tr {
      height: 1.5em;
      td {
        div {
          height: 1.5em;
          display: block;
        }
      }
    }
  }

  .progress__date div {
    display: block;
    font-size: 1.2em;
  }

  .progress__diff {
    margin-right: auto;
    float: left;
    font-size: 0.8em;
    margin-left: 10px;
    position: absolute;
  }

  .progress__value {
    width: 90px;
    text-align: right;
    flex-direction: column;
    float: right;
  }
}



.progress--latest {
  color: #fff;
  text-align: right;

  &__date {
    color: rgba(255,255,255,0.65);
  }
}

.progress--paginated {
  color: rgba(255, 255, 255, 0.65);
}

.progress--widget-latest {
  width: 30%;
}

.progress--widget-paginated {
  width: 45%;
}

.progress--full-page {
  //width: 7.14286%;
}
